import { Controller } from 'stimulus'

export default class ForwardableEmail extends Controller {

  static targets = [
    'emailPreferenceForm',
    'selectedTemplate',
  ]

  static values = {
    emailaccess: String,
    emailprovider: String,
    ismobile: Boolean,
  }

  static modals = {
    'templateOrForwardable': 'modal_template_or_forwardable',
    'emailPreference': 'modal_email_preference',
    'forwardableSent': 'modal_forwardable_sent',
    'useTemplate': 'modal_use_template'
  }

  connect() {
    this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    this.askedAboutEmailPreference = this.emailaccessValue.length > 0

    // keep it live, switch contents of this element
    this.container = document.querySelector('#intro-email-modal')

    const params = new URLSearchParams(location.search)
    if (params.get('forward')) {
      this.unHideModal()
    }
  }

  modalVisible() {
    const modal = document.querySelector('#modal-forwardable_email_copy')
    return !modal.classList.contains('hide')
  }

  unHideModal() {
    const modal = document.querySelector('#modal-forwardable_email_copy')
    modal.classList.remove('hide')
  }

  resend(event) {
    event.preventDefault()

    const { currentTarget: target, params: { url } } = event
    const originalContent = target.textContent

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.token,
      },
      body: JSON.stringify({})
    }).finally(() => {
      target.textContent = 'Sent!'
      setTimeout(() => { target.textContent = originalContent }, 2000)
    })
  }

  positionOverflowGradient(modal) {
    // alert('position')
    // to do: use stimulus target instead, nah :)
    const modalGradient = modal.querySelector('.modal-overflow-gradient')
    const modalButtons = modal.querySelector('.modal-buttons')

    // Check if buttons exist in the modal
    // set the appropriate position
    if (modalButtons && modalButtons.offsetParent != null) {
      const height = modalButtons.offsetHeight
      modalGradient.style.bottom = height + 'px'
    }
  }

  //
  // functions below are used in the modal to send forwardable or templated email
  //

  async fetchPartial(key, query = {}) {
    const { pathname } = new URL(window.location.href)
    const modalpath = ForwardableEmail.modals[key]

    const querystring = new URLSearchParams(query)
    const fullpath = [`${pathname}/${modalpath}`, querystring.toString()].join('?')

    return fetch(fullpath).then(async (response) => {
      return await response.text()
    })
  }

  async showModal(key, options = {}) {
    if (!this.askedAboutEmailPreference) {
      this.postPreferenceModal = [key, options]
      key = 'emailPreference'
    }

    const html = await this.fetchPartial(key, options)
    this.container.innerHTML = html

    if (!this.modalVisible()) this.unHideModal()

    // Set position of gradient overlay
    // to indicate content may be
    // available on scroll
    this.positionOverflowGradient(document.querySelector('#modal-forwardable_email_copy'))
  }

  async viewTemplate(event) {
    event.preventDefault()
    await this.showModal('useTemplate', { template: this.selectedTemplateTarget.value })
  }

  //
  // found this on SO in an effort to avoid popup windows being blocked.
  openWebMail(href) {
    if (href == null) return
    const a = document.createElement('a')
    a.setAttribute('href', href)
    a.setAttribute('target', '_blank')
    a.click()
  }

  async launchTemplateEmail(event) {
    const { params: { ismobile } } = event

    if (!this.askedAboutEmailPreference) {
      return await this.showModal('useTemplate', { template: this.selectedTemplateTarget.value })
    }

    const { pathname } = new URL(window.location.href)
    const url = `${pathname}/modal_interpolate?template=${this.selectedTemplateTarget.value}`

    const { to, subject, body, webmail } = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      return response.json()
    })

    if (!ismobile && this.emailaccessValue === 'web') {
      //
      // No provider returned for the user, just show the interpolated
      // template so they can cut & paste.
      if (webmail.trim().length === 0) {
        return await this.showModal('useTemplate', { template: this.selectedTemplateTarget.value })
      }

      const href = this.constructWebmailLink({
        link: webmail,
        to,
        subject,
        body: body,
      })

      return this.openWebMail(href)
    }

    const href = `mailto:?to=${encodeURIComponent(to || '')}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
    window.location.href = href
  }

  async sendForwardableEmail() {
    //
    // Cheat, we don't need to check for
    // preferences when sending the forward.
    const asked = this.askedAboutEmailPreference
    this.askedAboutEmailPreference = true

    await this.showModal('forwardableSent')
    //
    // Revert now that mail is sent and modal up shown
    this.askedAboutEmailPreference = asked
  }

  async cancelEmailPreference(event) {
    event.preventDefault()

    // Don't ask about this preference until next time
    this.askedAboutEmailPreference = true
    this.emailaccessValue = 'dont_ask'

    await this.showModal('templateOrForwardable')
  }

  async saveEmailPreference(event) {
    event.preventDefault()

    // Remember that we've seen the preferences modal
    this.askedAboutEmailPreference = true

    const formData = new FormData(this.emailPreferenceFormTarget)
    const preference = formData.get('preference')
    this.emailaccessValue = preference

    await fetch('/preferences', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.token,
      },
      body: JSON.stringify({
        key: 'email_client_access',
        value: preference,
      })
    })

    return await this.showModal(...this.postPreferenceModal)
  }

  async closeEmailTemplate(event) {
    event.preventDefault()
    await this.showModal('templateOrForwardable')
  }

  useInterpolatedTemplate(event) {
    const { params: { webmail } } = event

    const formSelector = `form#template-${this.selectedTemplateTarget.value}.read-only`
    const form = document.querySelector(formSelector)
    const formData = new FormData(form)

    const subject = formData.get('subject')
    const body = formData.get('body')

    let recipients = formData.get('recipients')
    // The weakest of all email regexs
    if (!recipients || !recipients.match(/@/g)) {
      recipients = ''
    }

    if (!this.ismobileValue && this.emailaccessValue === 'web') {
      const href = this.constructWebmailLink({
        link: webmail,
        subject,
        body: body,
      })
      return this.openWebMail(href)
    }

    const href = `mailto:${encodeURIComponent(recipients)}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
    this.openWebMail(href)
  }

  constructWebmailLink({ to, link, subject, body }) {
    const subjectParam = this.emailproviderValue === 'gmail' ? 'su' : 'subject'
    const paramsStarted = (s) => s.indexOf('?') >= 0

    let url = link
    url += paramsStarted(url) ? `&` : `?`
    url += `to=${encodeURIComponent(to)}&`
    url += `${subjectParam}=${encodeURIComponent(subject)}`
    url += paramsStarted(url) ? `&` : `?`
    url += `body=${encodeURIComponent(body)}`

    return url
  }
}

