import { Controller } from 'stimulus'
import autocomplete from 'autocomplete.js'

export default class JobRoleAutosuggest extends Controller {

  static targets = ['jobRoleName']

  sourceData() {
    const url = '/job_roles/autosuggest'

    return (query, callback) => {
      if (query.length > 1) {
        fetch(`${url}?name=${encodeURIComponent(query.slice(0, 64))}`)
          .then(response => response.json())
          .then(callback)
          .catch((_) => callback([]))
      }
    }
  }


  clearSearch() {
    const idField = document.getElementById('job_role_id')
    if (this.jobRoleNameTarget?.value == "" || idField?.value != "") {
      if (idField && idField.value != "") {
        idField.value = ''
        this.manuallyTriggerEvent(idField)
      }
    }
  }

  connect() {
    const idField = document.getElementById('job_role_id')

    const ac = autocomplete(this.jobRoleNameTarget, { hint: false }, [{
      source: this.sourceData(),
      debounce: 200,
      templates: {
        suggestion(suggestion) {
          return suggestion[1]
        },
      },
      change: () => {
        if (idField) {
          idField.value = ''
          this.manuallyTriggerEvent(idField)
        }
      }
    }])
      .on('autocomplete:selected', (_, suggestion) => {
        if (idField) {
          idField.value = suggestion[0]
          this.manuallyTriggerEvent(idField)
        }
        ac.autocomplete.setVal(suggestion[1])
      })
  }

  manuallyTriggerEvent(element) {
    var evt = document.createEvent("HTMLEvents");
    evt.initEvent("change", false, true);
    element.dispatchEvent(evt);
  }

  setSearchIconBackgroundPosition() {
    const width = this.jobRoleNameTarget.offsetWidth
    if (width >= 800) {
      this.jobRoleNameTarget.style.backgroundPosition = '96% center'
    } else if (width >= 650 && width < 800) {
      this.jobRoleNameTarget.style.backgroundPosition = '95% center'
    } else if (width >= 500 && width < 650) {
      this.jobRoleNameTarget.style.backgroundPosition = '94% center'
    } else if (width >= 400 && width < 500) {
      this.jobRoleNameTarget.style.backgroundPosition = '92% center'
    } else if (width >= 350 && width < 400) {
      this.jobRoleNameTarget.style.backgroundPosition = '90% center'
    } else if (width >= 300 && width < 350) {
      this.jobRoleNameTarget.style.backgroundPosition = '87% center'
    } else {
      this.jobRoleNameTarget.style.backgroundPosition = '84% center'
    }
  }

  publishInputChangeEvent(event) {
    document.dispatchEvent(new CustomEvent('JobRoleAutosuggestInputChange', {
      detail: {
        elementId: event?.target?.id
      }
    }))
  }
}
