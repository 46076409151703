import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [ "object", "category", "found" ];
    static values = { 
        fieldName: String,
        env: String
     }

    connect() {
        // Look for the form before accessing elements on that form
        const formPresent = document.querySelector("#form-present")
        if (formPresent) {
            // Only reveal the first object_name and column_name element in the displayed list
            const objElem = document.querySelector(".object_name");
            const fieldElem = document.querySelector(".column_name");
            objElem.classList.remove("hidden");
            fieldElem.classList.remove("hidden");
            document.querySelector("#spinner").classList.add('hide');
        }
        if (this.buttonsClicked()) {
          this.scrollToBottom();
          localStorage.setItem('buttonsClicked', 'false');
        }
    }

    deleteCriteria() {
        const criteriaContainer = document.querySelector("#criteria-div :nth-child(4)");
        const criteriaElem = event.target.parentElement.parentElement;
        if (criteriaContainer != criteriaElem) {
            criteriaElem.remove();
        }
    }

    addCriteria() {
        // Get our elements
        const elemToClone = event.target.parentElement.parentElement;
        let elemToModify = event.target.parentElement.parentElement;
        const targetAddDiv = document.querySelector("#criteria-div");
        // Copy the add row from the "add" click
        const valueRowClone = elemToClone.cloneNode(true);
        valueRowClone.querySelector(".object_name").classList.add("hidden");
        valueRowClone.querySelector(".column_name").classList.add("hidden");
        // Change buttons and reveal "operator" element
        elemToModify.querySelector(".btn-add").classList.add("hide");
        elemToModify.querySelector(".btn-delete").classList.remove("hide");
        elemToModify.querySelector(".operator").classList.remove("hide");
        elemToModify.querySelector("#value").required = true;
        //  Get the target that we will add the new row to    
        let valueText = valueRowClone.querySelector("#value");
        valueText.value =  null;
        targetAddDiv.append(valueRowClone);
        }

    addCategory() {
        const valueElem = event.target.parentElement.parentElement;
        let elemToModify = valueElem;
        const targetElem = valueElem.parentElement;
        const valueClone = valueElem.cloneNode(true);
        valueClone.querySelector('input').value = null;
        elemToModify.querySelector(".operator").classList.remove("hide");
        elemToModify.querySelector(".btn-add").classList.add("hide");
        elemToModify.querySelector(".btn-delete").classList.remove("hide");
        targetElem.append(valueClone);
    }

    deleteCategory() {
        const targetElem = event.target.parentElement.parentElement;
        targetElem.remove();
    }

    objectChange() {
        if (document.querySelector('#object_name').value == "Account") {
            document.querySelectorAll('[data-object="Account"]').forEach(option => option.classList.remove('hide'));
            document.querySelectorAll('[data-object="Opportunity"]').forEach(option => option.classList.add('hide'));
        } else if (document.querySelector('#object_name').value == "Opportunity") {
            document.querySelectorAll('[data-object="Opportunity"]').forEach(option => option.classList.remove('hide'));
            document.querySelectorAll('[data-object="Account"]').forEach(option => option.classList.add('hide'));
        }
    }

    catObjectChange() {
        if (this.categoryTarget.value == "Account") {
            document.querySelectorAll('[data-cat="Account"]').forEach(option => option.classList.remove('hide'));
            document.querySelectorAll('[data-cat="Opportunity"]').forEach(option => option.classList.add('hide'));
        } else if (this.categoryTarget.value == "Opportunity") {
            document.querySelectorAll('[data-cat="Opportunity"]').forEach(option => option.classList.remove('hide'));
            document.querySelectorAll('[data-cat="Account"]').forEach(option => option.classList.add('hide'));
        }
    }

    checkLogicEvent() {
        document.querySelector("#spinner").classList.remove('hide');
        this.foundTarget.classList.remove('hide');
        localStorage.setItem('buttonsClicked', 'true');
    }
    
    updateEvent() {
        document.querySelector("#spinner").classList.remove('hide');
        this.foundTarget.classList.add('hide');
        localStorage.setItem('buttonsClicked', 'true');
    }

    toggleSync() {
        let toggle_value = document.querySelector('#toggle_sync').checked;
        fetch(`/toggle_sync/${toggle_value}`)
        .then(response=> {
        })
        .catch((err) => {
            console.error('Error....')
            console.error(err)
            })
        }
    
    buttonsClicked() {
        const buttonsClicked = localStorage.getItem('buttonsClicked');
        return buttonsClicked === 'true';
    }
    
    scrollToBottom() {
        window.scrollTo({
                top: document.body.scrollHeight,
                left: 0,
                behavior: "instant"
        });
    }
}
